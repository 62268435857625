import { useState, useEffect } from 'react';
import { Page, Header, Content } from '@backstage/core-components';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TextField } from '@material-ui/core';
import useSWR from 'swr';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { useFetchJsonFromGHRepo } from '@internal/backstage-plugin-frontend-common-react';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import LinkIcon from '@material-ui/icons/Link';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@material-ui/core';


const GLOSSARY_API_URL = "/platform/glossary";

type GlossaryEntry = {
  term: string;
  definition: string;
  relatedTerms: string[];
  category: string;
  link: string;
};

const headerStyles = {
  cursor: 'pointer',
  padding: '8px 16px',
};

const StyledTableHead = withStyles(() => ({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'inherit',
  },
}))(TableHead);

const findDuplicateTerms = (data: GlossaryEntry[]) => {
  const termFrequency: { [term: string]: number } = {};
  const duplicates: string[] = [];

  data.forEach(entry => {
    if (termFrequency[entry.term]) {
      termFrequency[entry.term] += 1;
    } else {
      termFrequency[entry.term] = 1;
    }
  });

  for (const term in termFrequency) {
    if (termFrequency[term] > 1) {
      duplicates.push(term);
    }
  }

  return duplicates;
};


export const GlossaryPage = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const [sortField, setSortField] = useState<keyof GlossaryEntry>('term');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const getSortIcon = (field: keyof GlossaryEntry) => {
    if (sortField === field) {
      return sortDirection === 'asc'
        ? <Tooltip title="Sorted Ascending"><ArrowUpwardIcon /></Tooltip>
        : <Tooltip title="Sorted Descending"><ArrowDownwardIcon /></Tooltip>;
    }
    return null;
  };

  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const [backendUrl, setBackendUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchBackendUrl = async () => {
      const url = await discoveryApi.getBaseUrl('backend');
      setBackendUrl(url);
    };
    fetchBackendUrl();
  }, [discoveryApi]);

  const fetcher = (url: RequestInfo) => fetchApi.fetch(url).then((res) => res.json());

  const { data: configData, error: configError } = useSWR(`${backendUrl?.replace('/backend', '')}${GLOSSARY_API_URL}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const fetchJsonContent = useFetchJsonFromGHRepo();
  const [githubData, setGithubData] = useState<GlossaryEntry[] | null>(null);

  useEffect(() => {
    if (configData) {
      fetchJsonContent<any>(configData.gitRepoUrl, configData.branch, configData.filePath)
        .then(data => setGithubData(data))
        .catch(error => {
          console.error("Error fetching GitHub content:", error);
        });
    }

    // console.log("[GlossaryPage.tsx - gitRepoUrl]: "+configData?.gitRepoUrl);
    // console.log("[GlossaryPage.tsx - branch]: "+configData?.branch);
    // console.log("[GlossaryPage.tsx - filePath]: "+configData?.filePath);

  }, [configData, fetchJsonContent]);


  const isLoading = !configData || !githubData;

  if (configError) {
    return <Alert severity="error">Could not fetch config: {configError.message}</Alert>;
  }

  const sortedData = (data: GlossaryEntry[]) => {
    return [...data].sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.toLowerCase().localeCompare(bValue.toLowerCase());
        return sortDirection === 'asc' ? comparison : -comparison;
      }

      return 0;
    });
  };

  const duplicateTerms = githubData ? findDuplicateTerms(githubData) : [];

  const filteredData = githubData
  ? sortedData(githubData).filter(entry =>
      entry.term.toLowerCase().includes(searchTerm.toLowerCase()) ||
      entry.definition.toLowerCase().includes(searchTerm.toLowerCase()) ||
      entry.relatedTerms.some(term => term.toLowerCase().includes(searchTerm.toLowerCase())) ||
      entry.category.toLowerCase().includes(searchTerm.toLowerCase())
    )
  : [];

  const handleSortChange = (field: keyof GlossaryEntry) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  return (
    <Page themeId="home">
      <Header title="Glossary" />
      <Content>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <TextField
            id="searchTerm"
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{ marginRight: '20px' }}
          />
        </div>

        <div>
          {duplicateTerms.length > 0 && (
            <Alert severity="warning">
              Warning: Found duplicate terms: {duplicateTerms.join(", ")}
            </Alert>
          )}
        </div>

        {isLoading && !configError ? (
          <CircularProgress />
        ) : (

          <Paper elevation={3} style={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
            <Table>
            <StyledTableHead>
                <TableRow>
                  <TableCell className="cell" onClick={() => handleSortChange('term')} style={{ ...headerStyles, fontSize: '1.3rem', fontWeight: 'bold', minWidth: '200px', maxWidth: '300px', wordWrap: 'break-word' }}>Term {getSortIcon('term')}</TableCell>
                  <TableCell className="cell" style={{ fontSize: '1.3rem', fontWeight: 'bold', minWidth: '300px', maxWidth: '600px', wordWrap: 'break-word' }}>Definition</TableCell>
                  <TableCell className="cell" style={{ fontSize: '1.3rem', fontWeight: 'bold', minWidth: '100px', maxWidth: '200px', wordWrap: 'break-word' }}>Related Terms</TableCell>
                  <TableCell className="cell" style={{ fontSize: '1.3rem', fontWeight: 'bold', minWidth: '100px', maxWidth: '100px', wordWrap: 'break-word' }}>Links</TableCell>
                  <TableCell className="cell" onClick={() => handleSortChange('category')} style={{ fontSize: '1.3rem', fontWeight: 'bold', minWidth: '100px', maxWidth: '100px', wordWrap: 'break-word' }}>Category{getSortIcon('category')}</TableCell>
                </TableRow>
                </StyledTableHead>
              <TableBody>
                {filteredData.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ maxWidth: '00px', wordWrap: 'break-word' }}>{entry.term}</TableCell>
                    <TableCell style={{ maxWidth: '600px', wordWrap: 'break-word' }}>{entry.definition}</TableCell>
                    <TableCell>{entry.relatedTerms.join(', ')}</TableCell>
                    <TableCell>
                      {entry.link ? (
                        <Link href={entry.link} // Replace with your desired URL
                          target="_blank" // Opens the link in a new tab/window
                          rel="noopener noreferrer" // Recommended for security when using target="_blank"
                          color="inherit" // Change the color as needed
                        >
                          <LinkIcon />
                        </Link>
                        ) : (
                          null
                        )}
                    </TableCell>
                    <TableCell>{entry.category}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Content>
    </Page>
  );
};

