//packages/app/src/scaffolder/CustomRepoPicker/CustomRepoPickerExtension.tsx
import { discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import FormControl from '@material-ui/core/FormControl';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useAsync from 'react-use/esm/useAsync';
import { Repository } from './types/RepoResponse';
import { createTemplateObject } from './utils/createTemplateObject';
import { getRepos } from './utils/getRepos';
/*
 This is the actual component that will get rendered in the form
*/
export const CustomRepoPicker = ({
  onChange,
  rawErrors,
  required,
  formData,
  schema,
  uiSchema,
  name,
}: FieldExtensionComponentProps<string>) => {
  const uiOptions = uiSchema['ui:options'];

  // Backstage Hooks
  const fetchApi = useApi(fetchApiRef);
  const [backendUrl, setBackendUrl] = useState<string>('');
  const discoveryApi = useApi(discoveryApiRef);

  useEffect(() => {}, [backendUrl]);

  /* Backend URL */
  useEffect(() => {
    const fetchBackendUrl = async () => {
      const url = await discoveryApi.getBaseUrl('custom-scaffolder');
      setBackendUrl(url);
    };
    fetchBackendUrl();
  }, [discoveryApi]);

  /* Repositories */
  const repoListData = useAsync(async () => {
    const response = await getRepos(fetchApi, backendUrl, 'github');

    let { repositories } = response;

    if (uiOptions?.templatesOnly) {
      repositories = repositories.filter(x => x.isTemplate == true);
    }

    return [createTemplateObject(repositories?.[0]), ...repositories].sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }, [fetchApi, backendUrl, uiOptions]);

  return (
    <FormControl margin="normal" required={required} error={rawErrors?.length > 0 && !formData}>
      <Autocomplete
        id={name}
        options={repoListData.value ?? []}
        autoHighlight
        getOptionLabel={(option: Repository) => option && option['name']}
        isOptionEqualToValue={(option: Repository, value) => {
          if (typeof value === 'string' && value === '') return false;
          return option['name'] === value.name;
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={`${schema.title}`}
            size="small"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
          />
        )}
        onChange={(_e, value) => {
          onChange(value as unknown as string, undefined, 'repo_name');
        }}
      />
      <FormHelperText id="entityName">{schema.description}</FormHelperText>
    </FormControl>
  );
};
