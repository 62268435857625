import { FetchApi } from '@backstage/core-plugin-api';
import { RepoResponse } from '../types/RepoResponse';

export async function getRepos(
  fetchApi: FetchApi,
  backendURL: string,
  location: string,
): Promise<RepoResponse> {
  const endpoint = `${backendURL}/form-data/repositories?location=${location}`;
  const response = await fetchApi.fetch(endpoint);

  if (response.status !== 200) {
    throw response;
  }

  return response.json();
}
