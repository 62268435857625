import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

import { githubCopilotApi, githubCopilotApiRef } from './api/github/copilot';

export const licensePlugin = createPlugin({
  id: 'license',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: githubCopilotApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) => githubCopilotApi({ discoveryApi, fetchApi }),
    }),
  ],
});

export const LicensePage = licensePlugin.provide(
  createRoutableExtension({
    name: 'LicensePage',
    component: () => import('./pages/LicensePage/LicensePage').then(m => m.LicensePage),
    mountPoint: rootRouteRef,
  }),
);
