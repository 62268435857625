import React, { useState, useEffect } from 'react';
import {
  Content, Header, ItemCardHeader, Page, ItemCardGrid
} from '@backstage/core-components';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useSWR from 'swr';
import {
  Card, CardActions, CardContent, Button, Chip, TextField, Link, CardMedia,
  Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFetchJsonFromGHRepo } from '@internal/backstage-plugin-frontend-common-react';
import Alert from '@mui/material/Alert';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';

const MAX_DESCRIPTION_LENGTH = 380; // Limiting the size of the description to 380 chars so that the cards size doesn't go overboard
const LEARNING_PATH_API_URL = "/platform/learningpaths";

type Path = {
  label: string;
  url: string;
  description?: string;
  hours?: number;
  minutes?: number;
  paths?: number;
  tags?: string;
};

type SearchAndFilterProps = {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  allTags: string[];
};

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    transition: '0.3s',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: '0 6px 10px 3px rgba(0, 0, 0, .3)',
    }
  },
  cardHeader: {
    height: '50px',
    overflow: 'hidden'
  }
});

const truncateDescription = (description?: string, maxLength: number = MAX_DESCRIPTION_LENGTH): string => {
  if (!description) {
    return '';
  }
  if (description.length <= maxLength) {
    return description;
  }
  return description.slice(0, maxLength);
}


const learningPathLengthInfo = (path: Path) => {
  const hoursText = path.hours === 1 ? 'hour' : 'hours';
  const minutesText = path.minutes === 1 ? 'minute' : 'minutes';

  const hours = path.hours ? `${path.hours} ${hoursText}` : '';
  const minutes = path.minutes ? `${path.minutes} ${minutesText}` : '';

  // if no hours or minutes
  if (!hours && !minutes) {
    return `${path.paths} learning paths`;
  }

  return `${hours} ${minutes} | ${path.paths} learning paths`;
};


const learningPathTags = (input: string | undefined): string[] => {
  if (!input) {
    return [];
  }
  return input.split(',').map(item => item.trim());
}


const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  searchQuery,
  setSearchQuery,
  selectedTags,
  setSelectedTags,
  allTags
  }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <TextField
          id="searchQuery"
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginRight: '20px' }}
        />
        <FormControl variant="outlined" style={{ width: '200px' }}>
          <InputLabel htmlFor="filterByTags">Filter by Tags</InputLabel>
          <Select
            multiple
            value={selectedTags}
            onChange={(e) => setSelectedTags(e.target.value as string[])}
            label="Filter by Tags"
            inputProps={{
              id: 'filterByTags',
            }}
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            {allTags.map(tag => (
            <MenuItem key={tag} value={tag}>
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
    );
};


const LearningPathCard = ({ path }: { path: Path }) => {
  const classes = useStyles();
  return (
    <Card key={path.label} className={classes.card}>
      <CardMedia>
        <ItemCardHeader
          title={path.label}
          subtitle={learningPathLengthInfo(path)}
        />
      </CardMedia>
      <CardContent>
        {learningPathTags(path.tags).map(tag => (
          <Chip key={tag} label={tag} />
        ))}
        <Typography variant="body2">
          {truncateDescription(path.description)}
        </Typography>
      </CardContent>
      <CardActions>
        <Link href={path.url} target="_blank" rel="noreferrer">
          <Button size="small">Learn More</Button>
        </Link>
      </CardActions>
    </Card>
  );
};


const LearningPathCards = () => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const [backendUrl, setBackendUrl] = useState<string | null>(null);
  useEffect(() => {
    const fetchBackendUrl = async () => {
      const url = await discoveryApi.getBaseUrl('backend');
      setBackendUrl(url);
    };
    fetchBackendUrl();
  }, [discoveryApi]);

  const fetcher = (url: RequestInfo) => fetchApi.fetch(url).then((res) => res.json());

  const { data: configData, error: configError } = useSWR(`${backendUrl?.replace('/backend', '')}${LEARNING_PATH_API_URL}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const fetchJsonContent = useFetchJsonFromGHRepo();
  const [githubData, setGithubData] = useState<Path[] | null>(null);

  useEffect(() => {
    if (configData) {
      fetchJsonContent<any>(configData.gitRepoUrl, configData.branch, configData.filePath)
        .then(data => setGithubData(data))
        .catch(error => {
          console.error("Error fetching GitHub content:", error);
        });
    }

    // console.log("[LearningPaths.tsx - gitRepoUrl]: "+configData?.gitRepoUrl);
    // console.log("[LearningPaths.tsx - branch]: "+configData?.branch);
    // console.log("[LearningPaths.tsx - filePath]: "+configData?.filePath);

  }, [configData, fetchJsonContent]);

  const isLoading = !configData || !githubData;

  if (configError) {
    return <Alert severity="error">Could not fetch config: {configError.message}</Alert>;
  }

  const allTags = Array.from(new Set(githubData?.flatMap(p => learningPathTags(p.tags))));

  const filteredData = githubData
  ? githubData.filter((p: Path) => {
      const tagsArray = learningPathTags(p.tags);
      if (selectedTags.length > 0 && !selectedTags.every(tag => tagsArray.includes(tag))) {
        return false;
      }
      if (
        searchQuery &&
        !p.label.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (!p.description || !p.description.toLowerCase().includes(searchQuery.toLowerCase())) &&
        !tagsArray.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()))
      ) {
        return false;
      }
      return true;
    })
  : [];

  return (
    <div>
      <SearchAndFilter
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        allTags={allTags}
      />
      {isLoading && !configError ? (
          <CircularProgress />
        ) : (
        <ItemCardGrid>
          {filteredData.map((path) => (
            <LearningPathCard key={path.label} path={path} />
          ))}
        </ItemCardGrid>
      )}
    </div>
  );
};


export const LearningPaths = () => {
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="Learning Paths" ></Header>
        <Content>
          <LearningPathCards />
        </Content>
      </Page>
    </SearchContextProvider>
  );
};

