import { DiscoveryApi, createApiRef, FetchApi } from '@backstage/core-plugin-api';
import { GetUsersResponse, Usage, GetUsageResponse } from '@internal/backstage-plugin-license-common';

type GithubCopilotApiOptions = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
};

export const githubCopilotApiRef = createApiRef<GithubCopilotApi>({
  id: 'plugin.license.github.copilot',
});

export type GithubCopilotApi = ReturnType<typeof githubCopilotApi>;

export const githubCopilotApi = ({ discoveryApi, fetchApi }: GithubCopilotApiOptions) => {
  const getUsers = async (organization: string, page = 1, pageSize = 10) => {
    const BASE_URL = await discoveryApi.getBaseUrl('license');

    const url = new URL(`${BASE_URL}/github/copilot/users`);

    url.searchParams.set('organization', organization);
    url.searchParams.set('page', page.toString());
    url.searchParams.set('pageSize', pageSize.toString());

    const response = await fetchApi.fetch(url.toString());

    return response.json() as Promise<GetUsersResponse>;
  };

  const getUsage = async (organization: string): Promise<Usage[]> => {
    const BASE_URL = await discoveryApi.getBaseUrl('license');

    const url = new URL(`${BASE_URL}/github/copilot/usage`);

    url.searchParams.set('organization', organization);

    const response = await fetchApi.fetch(url.toString());

    return response.json() as Promise<GetUsageResponse>;
  }


  return {
    getUsers,
    getUsage
  }
};
