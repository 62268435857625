import { useMemo } from 'react';
import { useApi, configApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { errorApiRef } from '@backstage/core-plugin-api';

export const useFetchJsonFromGHRepo = () => {
  const errorApi = useApi(errorApiRef);
  const fetchApi = useApi(fetchApiRef);
  const configApi = useApi(configApiRef); // Move this outside of fetchJsonContent
  const backendUrl =
    configApi.getOptionalString('backend.baseUrl') || 'fallback-url-if-not-defined';
  const actualBackendUrl = backendUrl.replace('/backend', '');

  const fetchJsonContent = async <T extends any>(gitRepoUrl: string, branch: string, filePath: string): Promise<T> => {
    if (!gitRepoUrl || !branch || !filePath) {
      throw new Error('Repository details are not provided.');
    }

    const apiUrl = `${actualBackendUrl}/api/platform/fetch-content`;
    const queryParams = new URLSearchParams({
      gitRepoUrl,
      branch,
      filePath,
    });

    try {
      const response = await fetchApi.fetch(`${apiUrl}?${queryParams}`);

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const content = await response.text();

      return JSON.parse(content) as T;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred.';
      errorApi.post(new Error(`Failed to fetch or parse the JSON. ${errorMessage}`));
      throw error;
    }
  };

  return useMemo(() => fetchJsonContent, [errorApi, actualBackendUrl]);
};
