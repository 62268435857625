import { Content, Header, InfoCard, Page, LinkButton } from '@backstage/core-components';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid } from '@material-ui/core';
import { SavingsTracker, SavingsLoader } from '../savings-tracker/SavingsTracker';
import { ConfigCatFeatureFlag, configCatApiRef } from '@internal/plugin-config-cat';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';

const NewsCards = () => {
  const configCatApi = useApi(configCatApiRef);

  const { value, loading } = useAsync(async () => {
    const config = await configCatApi.getValue<string>('savings-tracker-config', '{}');

    return JSON.parse(config);
  }, [configCatApi]);

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <ConfigCatFeatureFlag flag="savings-tracker-enabled">
              {loading ? (
                <SavingsLoader />
              ) : (
                <InfoCard title="💲💲💲 LET'S SAVE SOME CASH - SAVINGS JULY 2024 💲💲💲">
                  <SavingsTracker
                    amountPledged={value.amountPledged}
                    description={value.description}
                    endDate={value.endDate}
                    goal={value.goal}
                    pledgeLink={value.pledgeLink}
                  />
                </InfoCard>
              )}
            </ConfigCatFeatureFlag>

            <br />
            <InfoCard title="📇 Software Catalog & Techdocs">
              <p>
                The 'Software Catalog' and 'TechDocs' features offer a suite of tools designed to
                help manage software and technical documentation with ease, whilst enabling more
                effective collaboration between teams.
              </p>

              <h3>📚 Software Catalog</h3>
              <p>
                A Software Catalog is a centralized hub that provides a comprehensive view of all
                the software applications and services within an organization. It is essentially a
                directory that lists all the available software components and their associated
                metadata, including version numbers, owners, maintainers, and dependencies. The
                Software Catalog in Backstage serves as a single source of truth for all
                software-related information, making it easier for teams to discover, use, and
                contribute to the various software components in their organization. It also enables
                efficient tracking of software components' usage, security vulnerabilities, and
                other relevant metrics, thus facilitating better decision-making and resource
                allocation.
              </p>
              <LinkButton
                color="primary"
                // editorconfig-checker-disable
                to="./../docs/default/component/ndp/features/catalog/how-to-add-a-catalog-entry"
                // editorconfig-checker-enable
              >
                More on Software Catalog!
              </LinkButton>
              <hr />
              <h3>📖 TechDocs</h3>
              <p>
                TechDocs is a documentation tool in the Backstage open-source platform that helps
                organizations manage and publish technical documentation for their software
                projects. It allows teams to create, edit, and maintain their documentation in a
                central location, with easy collaboration and version control features. TechDocs
                also supports various formats for documentation, including Markdown, Swagger,
                OpenAPI, and more. This tool aims to improve the visibility and accessibility of
                technical documentation for developers, engineers, and other stakeholders in an
                organization.
              </p>
              <LinkButton
                color="primary"
                // editorconfig-checker-disable
                to="./../docs/default/component/ndp/features/techdocs/how-to/how-to-create-techdocs"
                // editorconfig-checker-enable
              >
                More on TechDocs!
              </LinkButton>
              <hr />
              <h3>🔤 Naming Conventions</h3>
              <p>
                To ensure consistency and clarity, it is important to follow the naming conventions
                when creating entries in the Software Catalog. Consistent naming practices also
                enhance the discoverability and usability of documentation with TechDocs. For
                detailed guidelines on naming conventions, please refer to the{' '}
                <u>
                  <a href="./../docs/default/component/ndp/features/catalog/naming-conventions">
                    Naming Conventions Documentation
                  </a>
                </u>
                .
              </p>
              <hr />
              <p>
                ℹ️ Learn more about these features by reading through the '
                <u>
                  <a href="https://backstage.io/docs/features/software-catalog/" target="_blank">
                    Core Features
                  </a>
                </u>
                ' documentation on Backstage{' '}
              </p>
              {/* <iframe width="420" height="220" src="https://www.youtube.com/embed/85TQEpNCaU0" title="What is Backstage? (Explainer Video)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
              {/* <div style={{ height: 80 }} /> */}
            </InfoCard>
            <br />
            <InfoCard title="ℹ️ About NDP - Nintex Developer Portal">
              <p>
                The Platform Team is introducing the Nintex Developer Portal. The goal is to provide
                a set of tools, Golden Paths and templates that promote self-service, autonomy,
                knowledge sharing and fast release cycles.
              </p>
              <p>
                This version has a 'Software Catalog', 'TechDocs' and 'Tech Radar' functionality.
                The portal is based on '
                <u>
                  <a href="https://backstage.io/" target="_blank">
                    Backstage
                  </a>
                </u>
                ' and will serve as the foundation for NDP.
              </p>
              <p>
                Please find us on{' '}
                <u>
                  <a href="https://nintex.slack.com/archives/C03DKPU4DPS" target="_blank">
                    #rnd_platform_ndp
                  </a>
                </u>{' '}
                for feedback, requests or help. A presentation deck with our vision for DevOps is
                also available{' '}
                <u>
                  <a
                    // editorconfig-checker-disable
                    href="https://nintex.sharepoint.com/:p:/s/TeamPlatformLeadership/EYXCbCalwUlBpTacVIe2agwBhTYJLLN0FKboA8_NkKOMog?e=gneXMh"
                    // editorconfig-checker-enable
                    target="_blank"
                  >
                    here
                  </a>
                </u>
                .
              </p>
              {/* <iframe width="420" height="220" src="https://www.youtube.com/embed/85TQEpNCaU0" title="What is Backstage? (Explainer Video)" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
              {/* <div style={{ height: 80 }} /> */}
            </InfoCard>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <InfoCard title="📰 Latest News">
          <div style={{ overflow: 'auto', overflowX: 'hidden', height: 1000 }}>
            <h2>📣 1 July 2024</h2>
            <h3>💲 Savings July is Back! 💲</h3>
            <p>Savings July is officially live! Help us achieve our $300k savings target for FY25 by finding ways to cut IT costs.</p>
            <p>Submissions are open so head over to the <a href="/savings-tracker" target="_blank">Savings Tracker</a> for details and instructions on submitting a pledge.</p>

            <hr />

            <h2>📣 28 May 2024</h2>
            <h3>✨ UPDATE: New Feature Release for NDP ✨</h3>
            <h3>Introducing NSP Launchpad: Streamlining Your Transition to the Nintex Shared Platform</h3>
            <p>🚀🚀 The Platform team is thrilled to unveil our latest innovation: <a href="/nsp-launchpad/start" target="_blank">NSP Launchpad</a>. As delivery teams design and plan their services for the Nintex Shared Platform (NSP), we've been developing tools to simplify this transition and reduce the pressures associated with it.</p>
            <h3>Key Features of NSP Launchpad</h3>
            <ul>
            <li><strong>Auto-Capture of Service Details</strong>: Automatically captures service-specific details based on definitions in the <a href="/catalog" target="_blank">Nintex Software Catalog</a>.</li>
            <li><strong>Infrastructure Value File Generation</strong>: Generates infrastructure value files in all required platform repositories, linked to populated PRs and ADO Work Items, serving as checklists for the teams.</li>
            <li><strong>Domain and Product Guidance</strong>: Provides comprehensive guidance on domains and available products.</li>
            <li><strong>Out-of-the-Box Dashboards</strong>: Sets up default Grafana dashboards that delivery teams can customize.</li>
            <li><strong>Autonomous End-to-End Experience</strong>: Ensures a fully autonomous process from start to finish.</li>
            <li><strong>Slack Integration</strong>: Offers seamless integration with Slack, providing links to open PRs and ADO Work Items.</li>
            <li><strong>Onboard Progress Tracking</strong>: Automatically <a href="/nsp-launchpad/onboarding-tracker" target="_blank">tracks onboarding progress</a> of all Nintex services from the catalog.</li>
            </ul>
            <p></p>
            <p>We are excited for you to try out <a href="/nsp-launchpad/start">NSP Launchpad</a>, and as always, let us know your{' '} <u> <a href="https://nintex.slack.com/archives/C03DKPU4DPS" target="_blank"> feedback </a> </u> {', '} on Slack, or submit any other ideas in our<span>&nbsp;</span><a target="_blank" href="https://nintex.ideas.aha.io/?project=PLAT">Ideas Portal</a><span>.</span></p>

            <hr />

            <h2>📣 15 March 2024</h2>
            <h3>✨ UPDATE: New Feature Release for NDP ✨</h3>
            <h3>Introducing "Platty" - Our New Engineering Chatbot:</h3>
            <p>We are excited to announce the launch of "Platty", the latest exciting addition to the Nintex Developer Portal - designed and built by Nintex using on OpenAI's GPT engine.</p>
            <p>From Platty:<br /><em>"I am an intelligent assistant designed to help Nintex developers with their engineering questions. My purpose is to provide information and guidance based on Nintex documentation. If you have any questions related to Nintex products or engineering practices, feel free to ask, and I will do my best to assist you."</em></p>
            <h3>Key Features</h3>
            <ul>
            <li><strong>Data Indexing</strong>: All our Wiki data, including our ADO Wiki and the NDP Wiki, is indexed to provide comprehensive answers.</li>
            <li><strong>GPT Integration</strong>: Utilizes OpenAI's GPT engine to understand prompts and provide Nintex-context-aware responses.</li>
            <li><strong>Basic Authentication</strong>: The front-end component uses a backstage proxy with basic access authentication to securely interact with the API endpoint.</li>
            <li><strong>Customizable Queries</strong>: Nintex R&amp;D team members can ask questions in natural language to quickly find specific information related to projects, documentation, or engineering practices, saving hours of searching through Wiki articles.</li>
            </ul>
            <h3>How to Use Platty</h3>
            <ul>
            <li><strong>Accessing the Chat</strong>: Navigate to the NDP and locate the <a href="/chat">"Platty" menu item</a> on the left.</li>
            <li><strong>Asking Questions</strong>: Type your question into the chat box in natural language, such as "How do I engage the Platform team?" or "What is the process for code reviews?" or even "Tell me about Nintex products as a fairy-tale story?"</li>
            <li><strong>Receiving Answers</strong>: After submitting your question, wait for Platty to process your query and display the answer. As this feature is in beta, responses might take up to 10-15 seconds.</li>
            </ul>
            <p></p>
            <p>We are excited for you to try out Platty, and as always, let us know your{' '} <u> <a href="https://nintex.slack.com/archives/C03DKPU4DPS" target="_blank"> feedback </a> </u> {', '}!</p>
            <p></p>

            <hr />

            <h2>📣 12 January 2024</h2>
            <h3>✨ UPDATE: New Feature Release for NDP ✨</h3>
            <p>We're thrilled to announce the release of several new features for NDP:</p>
            <h3>New Templates:</h3>
            <p>
              Your feedback has been heard, and we are excited to introduce the first template
              developed based on requests from our engineering teams via the 💡{' '}
              <u>
                <a href="https://nintex.ideas.aha.io/?project=PLAT" target="_blank">
                  Platform Ideas portal
                </a>
              </u>
              . The{' "'}
              <u>
                <a href="./../create/templates/default/micro-frontend-repo" target="_blank">
                  New Micro-frontend Repository
                </a>
              </u>
              {'" '}
              template is now at your disposal.
            </p>
            <h3>Platform Ideas:</h3>
            <p>
              Introducing a new initiative from the Platform team: The 💡{' '}
              <u>
                <a href="https://nintex.ideas.aha.io/?project=PLAT" target="_blank">
                  Platform Ideas portal
                </a>
              </u>
              . This space is dedicated to capturing ideas and recommendations that engineering
              teams wish to see implemented by the Platform team. Whether your suggestion is minor
              or monumental, we welcome them all. If there are specific tools, templates,
              documentation, or process improvements you envision to elevate the efficiency of your
              delivery team, kindly submit your proposals{' '}
              <u>
                <a href="https://nintex.ideas.aha.io/?project=PLAT" target="_blank">
                  here
                </a>
              </u>
              . We value your input and look forward to collaborating on innovations that will
              enhance your work experience.
            </p>
            <p>
              We eagerly anticipate your{' '}
              <u>
                <a href="https://nintex.slack.com/archives/C03DKPU4DPS" target="_blank">
                  feedback
                </a>
              </u>
              {', '} as always.
            </p>

            <hr />

            <h2>📣 07 November 2023</h2>
            <h3>✨ UPDATE: New Feature Release for NDP ✨</h3>
            <p>We're thrilled to announce the release of several new features for NDP:</p>

            <h3>User Experience:</h3>

            <p>
              The Nintex Developer portal has undergone a significant transformation with an updated
              interface for improved navigation and a fresh look and feel.
            </p>
            <p>
              The menu layout has been refined to facilitate quicker access to essential areas
              within the portal. Our home page has been personalized with enhancements, enabling
              users to mark key features and areas as favorites, granting faster accessibility.
              <br />
              The News section now has its own dedicated space accessible from the main menu ribbon
              on the left of the page. We'll continuously update this section with announcements and
              upcoming news.
            </p>

            <h3>Learning Paths:</h3>
            <p>
              Introducing a new feature within NDP. This area will be the central hub for all
              learning-related content in R&D. From short 5-minute instructional videos to more
              advanced resources such as our OWASP Security Framework, you'll find various learning
              materials{' '}
              <u>
                <a href="./../learning-paths" target="_blank">
                  here
                </a>
              </u>
              .
              <br />
              We'll consistently update the content as it evolves..
            </p>
            <p>
              We eagerly anticipate your{' '}
              <u>
                <a href="https://nintex.slack.com/archives/C03DKPU4DPS" target="_blank">
                  feedback
                </a>
              </u>
              {', '} as always.
            </p>

            <hr />

            <h2>📣 25 October 2023</h2>
            <h3>✨ UPDATE: New Feature Release for NDP ✨</h3>
            <p>We are excited to announce the release of some new features for NDP:</p>
            <h3>Templates:</h3>
            <p>
              This feature serves as an internal automation tool and is a part of our Self Service
              initiative. It streamlines and simplifies previously manual operations by providing a
              library of pre-designed templates. These templates are ready-made, customizable
              frameworks for various tasks and processes within our organization. They enhance
              efficiency and standardization across the organization, making routine tasks more
              straightforward and allowing teams to focus on higher-value work. The Templates can be
              accessed{' '}
              <u>
                <a href="./../create" target="_blank">
                  here
                </a>
              </u>
              .
            </p>
            <p>
              Our first official template is designed for creating and onboarding new services into
              GitHub. It automates the previously manual process of setting up your service's GitHub
              repository while adhering to the standards outlined in our GitHub-Settings{' ('}
              <u>
                <a href="https://github.com/NintexGlobal/platform-github-settings" target="_blank">
                  platform-github-settings
                </a>
              </u>
              {') '}
              repository. We're excited to announce that many more templates are in the planning and
              development phases and will soon become part of the Self Service Toolbox. Please reach
              out to the Platform team with your thoughts and ideas on templates that can contribute
              to the Library.
            </p>
            <h3>Glossary:</h3>
            <p>
              This is a new addition to NDP and will serve as our reference guide, providing
              definitions and explanations of key terms and phrases specific to subjects, fields, or
              topics within our Engineering organization. Click{' '}
              <u>
                <a href="./../glossary" target="_blank">
                  here
                </a>
              </u>{' '}
              to view the Glossary
            </p>
            <p>These new features can be accessed from the main menu ribbon.</p>
            <p>
              As always, we look forward to your{' '}
              <u>
                <a href="https://nintex.slack.com/archives/C03DKPU4DPS" target="_blank">
                  feedback
                </a>
              </u>
              .
            </p>
          </div>
        </InfoCard>
        <br />
        <InfoCard title="🗞️ Previously on NDP">
          <div style={{ overflow: 'auto', overflowX: 'hidden', height: 600 }}>
            <h2>📣 06 September 2023</h2>
            <h3>💲 Savings July Update 💲</h3>
            <p>
              We would like to thank everyone for their pledges towards our Savings July initiative.
              We managed to pledge a massive $354 876. Currently the pledges are being vetted by the
              Platform team, and work items allocated to each pledge and team to action. Please keep
              a lookout on the relevant slack channels for further communication on this.
            </p>
            <hr />
            <h2>📣 12 July 2023</h2>
            <h3>💲 Savings July 💲</h3>
            <p>
              As communicated by Nick, this year's savings event 'Reduce our COGS: Azure or
              otherwise ' is live. See info card for details.
            </p>
            <hr />
            <h2>📣 07 June 2023</h2>
            <h3>✨ UPDATE: New Feature Release for NDP ✨</h3>
            <p>
              The Software Catalog and TechDocs features can now be set up for repositories hosted
              on both GitHub and Azure DevOps. Whether your code resides in GitHub or Azure DevOps,
              you can leverage the powerful capabilities of these two features on NDP.
            </p>
            <hr />
            <h2>📣 06 April 2023</h2>
            <h3>⚡ Nintex Shared Platform is Live ⚡</h3>
            <p>
              After a tremendous effort and collaboration between the <b>Platform</b> and{' '}
              <b>eSign</b> teams, we are happy to share the news that <b>NSP</b> (Nintex Shared
              Platform) went live in <b>Production</b> on Monday the 27th of March 2023! 🎉
            </p>
            <p>
              NSP is a multi-tenant platform powered by K8s on AKS, with heavy focus on 'developer
              delight' by providing delivery teams high autonomy that lets them focus on delivering
              features faster - whilst the Platform team takes care of the infrastructure,
              surrounding tools, and processes.
            </p>
            <p>
              We will continue to mature NSP and onboard more teams and services. In the meantime,
              you can read more about NSP on the{' '}
              <u>
                <a
                  href="https://dev.azure.com/nintex/Nintex/_wiki/wikis/Nintex.wiki/9898/NSP-Nintex-Shared-Platform"
                  target="_blank"
                >
                  wiki
                </a>
              </u>{' '}
              😍.
            </p>
            <hr />
            <h3>✨ New Feature Release for NDP ✨</h3>
            <p>
              We are excited to announce the release of two new features to NDP:
              <ul>
                <li>
                  📖<b>TechDocs</b> and
                </li>
                <li>
                  📚<b>Software Catalog</b>
                </li>
              </ul>
            </p>
            <p>
              These features are designed to help you streamline your software development process
              and make it easier for teams to collaborate, manage technical documentation and find
              the information they need.
            </p>
            <p>The features can be accessed in the menu items to the left.</p>
            <p>
              We look forward to your{' '}
              <u>
                <a href="https://nintex.slack.com/archives/C03DKPU4DPS" target="_blank">
                  feedback
                </a>
              </u>{' '}
              once you give these new features a try 😉.
            </p>
            <h3>23 November 2022</h3>
            <p>
              <h4>💰 Saving's June Winners 💰</h4>
              <ul>
                <li>
                  🏆 Total savings winners: <b>Hitomi</b>
                </li>
                <li>
                  🏆 Biggest savings winners: <b>HummingBirds</b>
                </li>
                <li>
                  🤑Total savings: <b>$903,607</b> <br />
                </li>
              </ul>
            </p>
            <p>
              <h4>
                ✋ 2022/2023 Holiday Season Changes and Deployments Embargo / Production Freeze
              </h4>
              <p>
                Each year we have a Holiday Season Embargo for both changes and deployments to
                Production environments. The <b>Platform and Infrastructure Team</b> is confirming
                the following dates <i>(AEST)</i>:
                <ul>
                  <li>
                    📦 Last Feature Release Date: <b>Thursday 15th December 2022 COB</b>
                  </li>
                  <li>
                    {' '}
                    🛠️ Last Change Date (inc Hotfixes): <b>Friday 16 December 2022 COB</b>{' '}
                  </li>
                  <li>
                    ❄️ Production Freeze: <b>Monday 19 December 2022 - Friday 6th January 2023</b>{' '}
                  </li>
                </ul>
              </p>
              <p>
                Any changes or deployments of a critical/high nature will be assessed on a
                case-by-case basis and require VP approval. This also includes security
                vulnerabilities. Work within non-production environments should continue
                uninterrupted. For more information, please refer to the Production Freeze -
                Christmas and New Year Leave (2022) Wiki.
              </p>
              <p>
                Please pass this on to any colleagues that should be across this. If you have any
                concerns or questions relating to the above, please reach out to the Platform and
                Infrastructure Team.
              </p>
            </p>
            <hr />
            <h3>03 August 2022: '2-for-1: Save some Azure and the planet'</h3>
            <p>
              Thank you for participating in “Save The Planet, One Server at a Time”. Overall, we
              had a great response with 27 pledges submitted.
            </p>
            <h4>So, what's next?</h4>
            <p>
              Teams need to submit their queries and/or the work item for their team's Save the
              Planet Submissions no later than <b>Friday 19th August 2022</b>.<br />
              Once the information has been submitted and verified by the Platform Team, the teams
              need to execute the submission and 'Save the Planet'. We ask that the work be
              completed no later than <b>Friday 23rd September 2022</b>.<br /> Once this has been
              completed, please confirm the details to the Platform Team{' '}
              <u>
                <a
                  href="mailto:platform@nintex.com?subject=
                        Save the Planet Submissions"
                >
                  (platform@nintex.com)
                </a>
              </u>
            </p>
            <p>
              If the above time frames are still not sustainable, please reach out to the Platform
              Team to arrange an alternative option.
            </p>
            <p>
              Thank you for participating in “Save The Planet, One Server at a Time”. Please reach
              out if you have any questions or concerns. &emsp;<b>- Platform Team</b>
            </p>
            <hr />
            <h3>23 June 2022: '2-for-1: Save some Azure and the planet'</h3>
            <p>
              Due to the late start we have decided to give teams another week to submit a pledge.
              There has been some great pledges and the target has been updated to reflect the
              amazing potential, the new date to get pledges in for is '8th July 2022'. Get
              submitting!
            </p>
            <hr />
            <h3>07 June 2022: '2-for-1: Save some Azure and the planet'</h3>
            <p>
              As communicated by Nick, the event '2-for-1: Save some Azure and the planet' is live.
              See card below for details.
            </p>
            <hr />
            <h3>11 May 2022 NDP: Nintex Developer Portal is Live! 🥳.</h3>
            <p>
              As announced on the Product All Hands, this is the very first release from the
              Platform Team. New features will be available soon, as we progress on the 'Align and
              Standardize' initiative. Stay tuned!
            </p>
            <p>
              Reach out to us on the channel{' '}
              <u>
                <a href="https://nintex.slack.com/archives/C03DKPU4DPS" target="_blank">
                  #rnd_platform_ndp
                </a>
              </u>
              .
            </p>
          </div>
        </InfoCard>
      </Grid>
    </Grid>
  );
};

export const NewsPage = () => {
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Header title="News Feed"></Header>
        <Content>
          <NewsCards />
        </Content>
      </Page>
    </SearchContextProvider>
  );
};

// ====================================================================================================================================
// NEWS PAGE RESTURCTURE CONTENT: to be enabled once an agreeable structure has been decided upon
// ====================================================================================================================================

// import React, { useState, useEffect } from 'react';
// import { Content, Header, InfoCard, ItemCardHeader, Page } from '@backstage/core-components';
// import { SearchContextProvider } from '@backstage/plugin-search-react';
// import CircularProgress from '@mui/material/CircularProgress';
// import Typography from '@mui/material/Typography';
// import useSWR from 'swr';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import { ErrorReport } from '../../common';
// import { Button, Chip, TextField, Link, Grid, CardMedia } from '@material-ui/core';
// import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
// import LinkIcon from '@mui/icons-material/Link';
// import { useApi, discoveryApiRef } from '@backstage/core-plugin-api';
// import { useFetchJsonFromGHRepo } from '@internal/backstage-plugin-frontend-common-react';
// import Alert from '@mui/material/Alert';
// import { HorizontalScrollGrid } from '@backstage/core-components';
// import NewspaperIcon from '@mui/icons-material/Newspaper';

// const NEWS_API_URL = "/platform/news";

// type News = {
//   date: string;
//   title: string;
//   description?: string;
//   link?: string;
//   tags?: string;
// };

// const useStyles = makeStyles({
//   card: {
//     width: '100%',
//     transition: '0.3s',
//     boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
//     '&:hover': {
//       transform: 'scale(1.03)',
//       boxShadow: '0 6px 10px 3px rgba(0, 0, 0, .3)',
//     },
//   },
// });

// const newsTags = (input: string | undefined): string[] => {
//   if (!input) {
//     return [];
//   }
//   return input.split(',').map(item => item.trim());
// }

// const NewsCards = () => {
//   const discoveryApi = useApi(discoveryApiRef);
//   const [backendUrl, setBackendUrl] = useState<string | null>(null);
//   const classes = useStyles();
//   const [selectedTags, setSelectedTags] = useState<string[]>([]);
//   const [searchQuery, setSearchQuery] = useState<string>('');

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchQuery(event.target.value);
//   };

//   useEffect(() => {
//     const fetchBackendUrl = async () => {
//       const url = await discoveryApi.getBaseUrl('backend');
//       setBackendUrl(url);
//     };
//     fetchBackendUrl();
//   }, [discoveryApi]);

//   const fetcher = (url: RequestInfo) => fetch(url).then((res) => res.json());

//   const { data: configData, error: configError } = useSWR(
//     `${backendUrl?.replace('/backend', '')}${NEWS_API_URL}`,
//     fetcher,
//     {
//       revalidateOnFocus: false,
//       revalidateOnReconnect: false,
//     }
//   );

//   const fetchJsonContent = useFetchJsonFromGHRepo();
//   const [githubData, setGithubData] = useState<News[] | null>(null);

//   useEffect(() => {
//     if (configData) {
//       fetchJsonContent(configData.gitRepoUrl, configData.branch, configData.filePath)
//         .then((data) => setGithubData(data))
//         .catch((error) => {
//           console.error("Error fetching GitHub content:", error);
//         });
//     }
//   }, [configData, fetchJsonContent]);

//   const isLoading = !configData || !githubData;

//   if (configError) {
//     return <Alert severity="error">Could not fetch config: {configError.message}</Alert>;
//   }

//   if (isLoading) {
//     return <CircularProgress />;
//   }

//   if (!githubData || githubData.length === 0) {
//     return (
//       <ErrorReport title="Could not fetch data." errorText="Unknown error" />
//     );
//   }

//   const allTags = Array.from(new Set(githubData.flatMap((p) => newsTags(p.tags))));

//   const handleTagChange = (
//     event: React.ChangeEvent<{ value: unknown }>
//   ) => {
//     setSelectedTags(event.target.value as string[]);
//   };

//   const filteredData = githubData.filter((p) => {
//     const tagsArray = newsTags(p.tags);
//     if (
//       selectedTags.length > 0 &&
//       !selectedTags.every((tag) => tagsArray.includes(tag))
//     ) {
//       return false;
//     }
//     if (
//       searchQuery &&
//       !p.tags?.toLowerCase().includes(searchQuery.toLowerCase()) &&
//       (!p.description ||
//         !p.description.toLowerCase().includes(searchQuery.toLowerCase())) &&
//       !tagsArray.some((tag) =>
//         tag.toLowerCase().includes(searchQuery.toLowerCase())
//       )
//     ) {
//       return false;
//     }
//     return true;
//   });

//   return (
//     <div>
//       <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
//         <TextField
//           label="Search"
//           variant="outlined"
//           value={searchQuery}
//           onChange={handleSearchChange}
//           style={{ marginRight: '20px' }}
//         />
//         <FormControl variant="outlined" style={{ width: '200px' }}>
//           <InputLabel>Filter by Tags</InputLabel>
//           <Select
//             multiple
//             value={selectedTags}
//             onChange={handleTagChange}
//             label="Filter by Tags"
//             renderValue={(selected) => (selected as string[]).join(', ')}
//             MenuProps={{
//               anchorOrigin: {
//                 vertical: 'bottom',
//                 horizontal: 'left',
//               },
//               transformOrigin: {
//                 vertical: 'top',
//                 horizontal: 'left',
//               },
//               getContentAnchorEl: null,
//             }}
//           >
//             {allTags.map((tag) => (
//               <MenuItem key={tag} value={tag}>
//                 {tag}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </div>

//       <div style={{ width: '100%', height: '100%', margin: 20 }}>
//         <HorizontalScrollGrid>
//           {filteredData.map((p, index) => (
//             <Grid item xs={12} key={index} style={{ marginBottom: 20 }}>
//               <Card style={{ width: '45vw', height: '85vh', margin: 2}} className={classes.card}>
//                 <CardMedia>
//                   <ItemCardHeader
//                     title={p.title}
//                     subtitle={<NewspaperIcon />}
//                   >
//                     <div style={{ display: 'flex', alignItems: 'flex-start' }}>
//                       {newsTags(p.tags).map((tag, tagIndex) => (<Chip key={tagIndex} label={tag} />))}
//                     </div>
//                   </ItemCardHeader>
//                 </CardMedia>
//                 <CardContent>
//                   <div style={{ display: 'flex', alignItems: 'flex-start', overflow: 'auto' }}>
//                     <InfoCard title={'Latest on NDP🗞️'} subheader={'📅 '+p.date}>
//                       <Typography>{p.description}</Typography>
//                     </InfoCard>
//                   </div>
//                   <Typography
//                     sx={{ fontSize: 14, marginRight: '10px' }}
//                     color="text.secondary"
//                     gutterBottom
//                   >
//                     {p.link && (
//                       <Link href={p.link} target="_blank" rel="noreferrer">
//                         <Button size="small">
//                           <LinkIcon />Link
//                         </Button>
//                       </Link>
//                     )}
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </HorizontalScrollGrid>
//       </div>
//       <br/>
//       <div style={{ width: '100%' }}>
//         <Grid container spacing={2}>
//           {filteredData.map((p, index) => (
//             <Grid item xs={12} key={index}>
//               <Card className={classes.card}>
//                 <CardContent>
//                   <div style={{ display: 'flex', alignItems: 'flex-start' }}>
//                     <Typography
//                       variant="h5"
//                       component="div"
//                       style={{
//                         marginRight: '20px',
//                       }}
//                     >
//                       {p.title}
//                     </Typography>
//                     <Typography component="div" color="text.secondary">
//                       {newsTags(p.tags).map((tag, tagIndex) => (
//                         <Chip key={tagIndex} label={tag} />
//                       ))}
//                     </Typography>
//                   </div>

//                   <Typography
//                     sx={{ fontSize: 14, marginRight: '10px' }}
//                     color="text.secondary"
//                     gutterBottom
//                   >
//                     {p.date}
//                     {p.link && (
//                       <Link href={p.link} target="_blank" rel="noreferrer">
//                         <Button size="small">
//                           <LinkIcon />Link
//                         </Button>
//                       </Link>
//                     )}
//                   </Typography>

//                   <Typography>{p.description}</Typography>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </div>
//     </div>
//   );
// };

// export const NewsPage = () => {
//   return (
//     <SearchContextProvider>
//       <Page themeId="home">
//         <Header title="News Feed" ></Header>
//         <Content>
//           <NewsCards />
//         </Content>
//       </Page>
//     </SearchContextProvider>
//   );
// };
