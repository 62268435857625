export function createTemplateObject<T>(obj: T): { [K in keyof T]: T[K] } {
  let templateObj: T = {} as { [K in keyof T]: T[K] };

  for (const key in obj) {
    if (Object.keys(obj as object).includes(key)) {
      const value = obj[key];

      let templateVal: any = '';
      switch (typeof value) {
        case 'number':
          templateVal = 0;
          break;

        case 'object':
          templateVal = createTemplateObject(value);
          break;

        case 'function':
          templateVal = value;
          break;

        default:
          templateVal = '';
          break;
      }

      templateObj[key] = templateVal;
    }
  }

  return templateObj;
}
